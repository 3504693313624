import React from 'react'
import Hero from "../../shared/Hero/Hero";
export default function Contact() {
  return (
    <div>
      <Hero hero='Contact'/>
     
    </div>
  )
}
