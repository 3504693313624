export const userHeader = [
    {
      dispaly: "Home",
      path: "/",
    },
    {
      dispaly: "Cart",
      path: "/cart",
    },
    {
      dispaly: "Menu",
      path: "/menu",
    },
    {
      dispaly: "Contact",
      path: "/contact",
    },
  ];
export const adminHeader = [
    {
      dispaly: "Home",
      path: "/",
    },
    {
      dispaly: "Cart",
      path: "/cart",
    },
    {
      dispaly: "Menu",
      path: "/menu",
    },
    {
        dispaly: "Items",
        path: "/item",
      },
      {
        dispaly: "Categories",
        path: "/category",
      },
      {
        dispaly: "Orders",
        path: "/order",
      },
    
    {
      dispaly: "Contact",
      path: "/contact",
    },
  ];;
